import React from 'react';
import styled from "@emotion/styled";


const PrimaryButton = styled.button`
  background: #11468C;
  padding: 4%;
  border: 0;
  font-size: 1.4em;
  color: lightgrey;
  border-radius: 5%;
`

const ButtonPrimary = ({children}) => (
    <PrimaryButton>
        {children}
    </PrimaryButton>
)

export default ButtonPrimary;