import React from "react";
import styled from "@emotion/styled";
import facepaint from 'facepaint'
const mq = facepaint([
    '@media(min-width: 424px)',
    '@media(min-width: 767px)',
    '@media(min-width: 1024px)'
])


const PrimarySection = styled('section')`
  ${mq({
    textAlign:'center',
    display:'flex',
    flexDirection: 'column',
    alignItems:'center',
    justifyContent:'center',
    padding:['3em 4% 5em', '5em 4% 8em', '8em 4% 10em'],
    clipPath:'polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 50px))',
    background:'#2c3b59',
    color:'#E8E8E8'
})};
`

const PrimeSection = ({children}) => (
    <PrimarySection>
        {children}
    </PrimarySection>
)

export default PrimeSection;