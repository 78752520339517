import * as React from "react"
import Layout from "../components/layout";
import PrimeSection from "../components/primesection";
import ButtonPrimary from "../components/button";
import styled from "@emotion/styled";
import facepaint from 'facepaint'
const mq = facepaint([
    '@media(min-width: 767px)',
    '@media(min-width: 1023px)',
    '@media(min-width: 1439px)'
])


const ContactForm = styled('form')`
  ${mq({
    div:{
        display:'flex',
        width:['100%', '100%', '80%', '60%'],
        margin:'0 auto',

      '&: first-of-type':{
      flexDirection:['column', 'row'],
      label: {
        marginTop:'0',
        width: '100%',
        padding:'2%',

        input: {
          width:'100%'
        }
      }
    },
      '&: nth-child(2n)':{
        flexDirection:'column',
        label: {
          marginTop:'0',
          width: '100%',
          padding:'2%',

          input: {
            width:'100%'
          },
        }
      },
        
        label:{
            
            input:{
                width: ['100%', '50%'],
                minHeight:'50px',
                outline:'none',
                outlineOffset:'none',
                padding: '2%',
                background: '#B8B8B9',

              '&:focus':{
                border:'4px solid #2b73d0',
                boxShadow:'0 0 3px #2b73d0'
              }
            },
            textarea:{
                width:'100%',
                minHeight: ['100px', '200px'],
                outline:'none',
                outlineOffset:'none',
                padding:'2%',
                background:'#B8B8B9',
                margin: '6% 0 8% 0',
              
              '&:focus':{
                border:'4px solid #2b73d0',
                boxShadow:'0 0 3px #2b73d0'
              }
            }
        },
      button:{
            minWidth:['100%', '280px', '280px'],
            margin:'0 auto'
      }
    },
    
})};
`

const ContactPage = () => {
    return (
        <Layout>
            <PrimeSection>
                <h1>Contact Me</h1>
                <p>If you’d like to chat about a project please fill in the form below and I’ll get back within 1-2 days.</p>
            </PrimeSection>

            <section>
                <ContactForm method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                    <div>
                        <label>
                            <h3>Name</h3>
                            <input type="text" name="name" />
                        </label>
                        <label>
                            <h3>Email</h3>
                            <input type="email" name="email" />
                        </label>
                    </div>
                    <div>
                        <label>
                            <h3>Message</h3>
                            <textarea name="message" />
                        </label>
                        <ButtonPrimary type="submit">
                            submit
                        </ButtonPrimary>
                    </div>
                </ContactForm>
            </section>
        </Layout>
    )
}

export default ContactPage;
